<template>
    <DropdownMenu v-if="languages.length > 1" :items="languages" icon="language" class="content-language-selector">
        <template #label>
            <Typography variant="body">{{ pageLanguageName }}</Typography>
        </template>

        <template #dropdownItem="{ item }">
            <DropdownMenuItem @click="onLanguageClick(item)">
                <Typography as="div" variant="body">{{ item.name }}</Typography>
            </DropdownMenuItem>
        </template>
    </DropdownMenu>
</template>

<script setup>
const { locales } = useI18n();
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    languages: {
        type: Array,
        default: () => [],
    },
});

function onLanguageClick(locale) {
    reloadAppWithLocale(locale.code);
}

const pageLanguageName = computed(() => {
    return getLocaleName(locales, pageLanguage) || '';
});
</script>

<style lang="scss" scoped></style>
